export default {
  aboutPurchase: {
    enable: true,
  },
  colorParameterId: '5c5722258ceb4749474e0125',
  brandParameterId: '5cb738b2e5cc4c0017ea7f53',
  metersParameterId: '610a4647e90d2200176e9238',
  ean: {
    pathHash: '',
    pathName: 'product-slug',
    queryKey: 'kod',
  },
  events: {
    sendProductsInFirstCheckoutStepOnly: false,
    unitsWithTax: true,
    dataLayerBasic: {
      enable: true,
      searchQuery: {
        minimumCharacters: 1,
      },
    },
    enhancedEcommerce: {
      enable: true,
      purchase: {
        unitsWithTax: true,
        revenueIncludeShipperPrice: true,
        revenueIncludePaymentPrice: true,
      },
    },
    heureka: {
      conversions: {
        enable: false,
      },
      shopCertification: {
        enable: false,
      },
      shopPurchase: {
        enable: true,
      },
    },
    quarticon: {
      enable: false,
    },
    targito: {
      enable: false,
      id: '',
      origin: '',
    },
  },
  functions: {
    bulkOrder: false,
    compare: false,
    currencyFromUrl: {
      enable: false,
      query: 'currency',
    },
    currencyFromIp: {
      enable: false,
    },
    documentsList: false,
    favourites: false, // WishList must be allowed too
    productWildcard: {
      enable: false,
      link: 'all',
    },
    shoppingLists: false,
    ssrPropertyPicker: false,
    wishList: false,
    warrantyClaims: false,
  },
  foxentry: {
    id: false, // Set foxentry ID
    routes: [], // Set allowed routes for foxentry
  },
  lightwidgetInstagramId: '',
  masterCategories: {},
  product: {
    manuals: false,
  },
  shopHomePagePath: 'eshop',
  infoBoxes: false,
  seo: {
    alternateLinks: true,
  },
  utm: {
    enable: false,
    cookie: {
      enable: false, // Enable, if want to save params into cookie
      expiration: 30, // Set cookie expiration in days || false (fallback is 365 days)
      name: 'X-Inspishop-UTM-Cookie', // Set cookie name
    },
  },
}
