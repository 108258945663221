import VeeValidate, { Validator } from 'vee-validate'
import Vue from 'vue'
import _ from 'lodash'

Vue.use(VeeValidate, {
  locale: 'cs',
  events: 'change|blur',
})

export default ({ $axios, app }, inject) => {
  Validator.extend('full_name', {
    getMessage: field => 'The ' + field + ' is not a full name.',
    validate: value => /^(.+)( .+)+$/g.test(value),
  })

  Validator.extend('phone_number', {
    getMessage: field => 'The ' + field + ' is not a phone number.',
    validate: value => /^[+][()/0-9. -]{9,}$/g.test(value),
  })

  Validator.extend('street_only', {
    getMessage: field => 'The ' + field + ' is not a street only.',
    validate: (value, args) => {
      console.log(value, args, args[0])
      const houseNumber = args && args[0]
      return value.indexOf(houseNumber) === -1
    },
  })

  Validator.extend('house_number', {
    getMessage: field => 'The ' + field + ' is not a house number.',
    validate: (value, args) => {
      const valueParts = value.split(' ')
      const street = args && args[0]
      return !(valueParts.length > 1 || value === street)
    },
  })

  Validator.extend('postcode', {
    getMessage: field => 'The ' + field + ' is not a zip code.',
    validate: (value, args) => {
      if (args && args[0] === 'AUT') {
        return /^\d{4}$/g.test(value)
      } else if (args && args[0] === 'DEU') {
        return /^\d{5}$/g.test(value)
      } else if (args && args[0] === 'CZE') {
        return /^\d{3} ?\d{2}$/g.test(value)
      } else if (args && args[0] === 'CHE') {
        return /^\d{4}$/g.test(value)
      } else {
        return true
      }
    },
  })

  Validator.extend('street_and_number', {
    getMessage: field => 'The ' + field + ' should contain street and house number.',
    validate: value => {
      return /^.*[^0-9]+.*\s+\d+.*$/.test(value)
    },
  })

  Validator.extend('tin', {
    getMessage: field => 'The ' + field + ' is not a valid tin.',
    validate: (value, args) => {
      if (args && args[0] === 'CZE') {
        try {
          let a = 0
          if (value.length == 0) return true
          if (value.length != 8) throw 1
          let b = value.split('')
          let c = 0
          for (let i = 0; i < 7; i++) a += parseInt(b[i]) * (8 - i)
          a = a % 11
          c = 11 - a
          if (a == 1) c = 0
          if (a == 0) c = 1
          if (a == 10) c = 1
          if (parseInt(b[7]) != c) throw 1
        } catch (e) {
          return false
        }
      }
      return true
    },
  })

  const customValidation = {
    messages: {
      email: field => app.i18n.t('globals.validation.messages.email', { field }),
      full_name: field => app.i18n.t('globals.validation.messages.full_name', { field }),
      phone_number: field => app.i18n.t('globals.validation.messages.phone_number', { field }),
      street_and_number: field => app.i18n.t('globals.validation.messages.street_and_number', { field }),
      street_only: field => app.i18n.t('globals.validation.messages.street_only', { field }),
      house_number: field => app.i18n.t('globals.validation.messages.house_number', { field }),
      postcode: field => app.i18n.t('globals.validation.messages.postcode', { field }),
      tin: field => app.i18n.t('globals.validation.messages.tin', { field }),
    },
  }

  import(`vee-validate/dist/locale/${app.i18n.locale}`).then(messages => {
    Validator.localize(app.i18n.locale, _.merge(messages, customValidation))
  })
}
